import PropTypes from 'prop-types';
// material
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  const theme = useTheme();
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    /*eslint-disable */
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg "
        width="100%" height="100%" viewBox="0 0 1345 1345">
        <g transform="translate(0.000000,1345.000000) scale(0.100000,-0.100000)"
          fill={PRIMARY_MAIN} stroke="none">
          <path d="M40 9694 c0 -2577 3 -3761 10 -3775 10 -18 13 -18 40 -4 17 9 30 22
30 30 0 16 75 196 110 266 11 21 29 57 40 78 11 22 20 48 20 58 0 10 12 41 25
68 14 28 25 57 25 66 0 8 16 38 35 65 19 27 35 55 35 63 0 7 9 30 19 50 11 20
26 56 34 81 41 123 104 265 135 302 8 10 18 38 23 64 5 26 16 55 24 64 8 9 15
23 15 32 0 8 11 37 25 64 14 27 30 64 36 82 6 18 21 45 34 60 13 15 27 46 31
67 4 22 15 56 25 75 10 19 30 64 44 100 15 36 41 97 60 135 19 39 43 90 53
115 11 25 32 71 46 104 14 32 26 68 26 80 0 12 11 37 23 56 33 49 97 181 97
200 0 9 9 33 19 54 11 21 23 52 26 69 4 18 15 46 25 62 10 17 21 41 25 55 4
14 19 42 33 63 14 21 33 59 43 85 9 26 22 63 29 82 13 35 29 72 94 211 20 41
36 79 36 83 0 9 45 109 70 158 11 20 20 42 20 48 0 6 13 39 29 73 16 34 46
100 66 147 21 47 43 92 50 100 8 8 16 33 20 55 4 22 16 54 26 71 11 17 19 43
19 56 0 14 13 42 29 62 17 21 35 54 42 74 7 20 20 48 30 61 11 13 19 36 19 50
0 14 8 42 19 61 10 19 22 51 26 70 4 19 13 42 20 50 16 19 95 181 95 195 0 6
9 29 20 50 11 22 25 58 31 80 6 22 17 47 24 55 7 8 16 26 20 40 6 22 65 152
101 222 8 14 14 33 14 41 0 8 11 35 25 61 14 26 25 53 25 60 0 7 27 66 60 132
33 65 60 125 60 132 0 7 9 30 20 52 11 22 20 46 20 54 0 9 21 53 46 98 41 73
68 138 114 268 7 19 23 56 36 81 13 26 24 55 24 64 0 9 11 30 25 47 13 16 30
48 36 71 6 23 19 58 29 77 31 60 68 147 75 178 3 17 12 36 20 42 8 6 17 26 20
43 4 18 22 61 40 97 61 122 85 175 85 194 0 10 9 29 20 43 11 14 20 32 20 40
0 8 11 36 25 62 40 76 95 207 95 228 0 10 7 28 15 39 8 10 20 37 26 59 6 22
22 52 35 68 13 16 24 35 24 42 0 7 13 42 30 77 16 34 30 75 30 91 l0 27 -1660
0 -1660 0 0 -3756z"/>
          <path d="M4496 13416 c-4 -19 -13 -39 -21 -46 -7 -6 -17 -24 -20 -40 -6 -27
-53 -133 -94 -210 -10 -19 -22 -51 -26 -70 -4 -19 -16 -46 -26 -59 -11 -13
-19 -29 -19 -36 0 -12 -38 -102 -91 -211 -16 -33 -29 -67 -29 -76 0 -8 -9 -26
-19 -40 -11 -13 -22 -41 -26 -61 -4 -20 -16 -48 -26 -61 -11 -13 -19 -30 -19
-38 0 -8 -18 -51 -40 -97 -22 -45 -40 -88 -40 -96 0 -7 -6 -18 -14 -24 -8 -6
-19 -33 -26 -59 -6 -26 -17 -54 -24 -62 -8 -8 -16 -28 -20 -43 -4 -16 -31 -79
-60 -140 -30 -62 -57 -123 -61 -137 -4 -14 -16 -39 -26 -56 -11 -17 -19 -39
-19 -48 0 -9 -9 -34 -19 -54 -54 -103 -91 -183 -91 -194 0 -7 -12 -35 -25 -63
-14 -27 -25 -54 -25 -59 0 -10 -50 -119 -76 -168 -8 -15 -26 -56 -39 -92 -14
-36 -29 -74 -34 -83 -23 -44 -71 -153 -71 -162 0 -5 -11 -31 -25 -57 -14 -26
-25 -52 -25 -59 0 -12 -48 -117 -89 -195 -10 -19 -22 -49 -25 -67 -4 -17 -14
-37 -22 -44 -8 -6 -14 -21 -14 -33 0 -12 -29 -80 -65 -151 -35 -72 -65 -138
-65 -147 0 -10 -9 -32 -20 -50 -11 -18 -20 -39 -20 -46 0 -7 -27 -70 -60 -139
-33 -69 -60 -131 -60 -138 0 -7 -8 -23 -19 -36 -10 -13 -21 -42 -25 -63 -4
-22 -16 -51 -27 -64 -10 -14 -19 -33 -19 -43 0 -11 -11 -40 -24 -66 -51 -101
-96 -198 -96 -210 0 -7 -11 -33 -25 -59 -14 -26 -25 -53 -25 -61 0 -8 -8 -31
-19 -51 -10 -20 -38 -77 -61 -127 -23 -49 -50 -107 -61 -127 -10 -20 -19 -44
-19 -53 0 -9 -12 -42 -26 -73 -72 -157 -97 -210 -114 -244 -11 -20 -20 -43
-20 -50 0 -7 -11 -35 -25 -63 -13 -27 -25 -54 -25 -60 0 -10 -67 -155 -100
-217 -11 -20 -20 -43 -20 -50 0 -8 -9 -30 -19 -51 -10 -20 -22 -49 -26 -64 -3
-16 -13 -35 -20 -43 -7 -8 -18 -34 -25 -58 -6 -23 -22 -59 -35 -79 -14 -19
-29 -52 -35 -72 -5 -20 -19 -49 -30 -66 -11 -16 -20 -38 -20 -48 0 -10 -11
-39 -25 -65 -14 -26 -25 -57 -25 -69 0 -12 -5 -25 -11 -29 -6 -3 -19 -26 -30
-50 -10 -24 -29 -65 -41 -91 -21 -47 -39 -92 -73 -183 -9 -25 -23 -52 -31 -61
-8 -8 -14 -23 -14 -31 0 -9 -18 -50 -40 -92 -22 -41 -40 -82 -40 -90 0 -8 -11
-36 -25 -62 -14 -26 -25 -54 -25 -62 0 -9 -7 -23 -16 -33 -9 -10 -20 -37 -25
-61 -6 -23 -16 -50 -23 -58 -6 -8 -31 -60 -55 -115 -23 -55 -50 -116 -60 -135
-10 -19 -22 -50 -26 -69 -7 -32 -37 -100 -77 -171 -10 -19 -26 -51 -34 -70 -8
-19 -23 -52 -34 -72 -11 -20 -20 -47 -20 -60 0 -13 -8 -36 -18 -53 -10 -16
-22 -42 -25 -56 -4 -14 -22 -52 -42 -84 -19 -33 -35 -66 -35 -74 0 -9 -12 -38
-25 -66 -14 -27 -25 -56 -25 -63 0 -7 -8 -30 -19 -50 -33 -64 -69 -144 -112
-247 -23 -55 -47 -109 -54 -120 -7 -11 -16 -35 -19 -52 -4 -17 -14 -37 -22
-43 -7 -7 -19 -31 -25 -54 -12 -48 -41 -114 -86 -205 -18 -35 -33 -71 -33 -80
0 -9 -8 -30 -19 -47 -10 -17 -22 -41 -26 -55 -3 -13 -31 -73 -60 -134 -50
-102 -61 -128 -95 -225 -7 -19 -17 -43 -21 -52 -5 -10 -13 -26 -17 -35 -5 -10
-27 -55 -50 -100 -23 -45 -42 -88 -42 -96 0 -19 -61 -161 -116 -268 -13 -26
-24 -53 -24 -60 0 -13 -71 -159 -105 -219 -13 -22 -15 -247 -15 -1632 l0
-1608 1704 0 1703 0 7 42 c4 23 15 56 25 73 10 16 21 42 25 58 4 15 20 49 35
75 16 26 33 61 37 77 3 17 13 37 20 45 7 9 18 38 24 65 6 27 17 56 24 65 7 8
16 28 20 44 4 17 17 43 28 59 24 33 84 174 93 220 4 18 13 39 20 47 7 8 17 30
24 49 6 19 18 42 26 51 14 15 74 165 96 237 5 18 14 35 19 38 5 3 14 22 21 43
6 20 20 48 30 61 11 13 19 32 19 43 0 19 21 69 85 203 18 39 37 81 40 95 4 14
13 32 20 40 8 8 16 29 20 45 6 28 35 93 91 208 13 27 24 54 24 60 0 7 11 33
25 59 14 26 25 56 25 66 0 11 8 30 19 43 19 24 52 100 98 219 13 35 28 69 32
75 5 5 11 24 15 40 4 17 13 37 20 45 14 15 33 58 106 235 23 55 49 116 60 136
10 19 21 51 25 71 4 20 16 44 26 54 10 9 19 24 19 33 0 9 7 29 15 45 35 67
105 236 105 254 0 10 9 27 19 36 11 10 22 30 26 44 4 15 21 59 40 97 18 39 45
104 61 145 15 41 36 86 46 99 10 13 18 30 18 38 0 8 11 36 25 62 13 25 38 84
56 131 29 80 46 118 105 233 13 26 24 52 24 58 0 6 16 47 36 92 35 81 42 97
103 229 17 37 31 73 31 80 0 8 6 22 14 33 15 23 91 197 102 235 4 14 15 41 25
60 11 19 19 42 19 51 0 9 9 24 19 33 11 10 23 32 27 50 3 17 14 45 24 61 9 17
27 59 40 95 13 36 29 74 37 85 7 11 13 27 13 35 0 9 11 31 24 49 14 19 28 48
32 65 4 17 15 47 25 66 11 19 19 42 19 51 0 8 3 18 6 22 3 3 21 40 40 83 18
42 39 84 47 93 8 9 18 32 22 51 4 19 16 49 26 66 10 17 19 37 19 45 0 13 7 30
79 182 22 49 41 97 41 107 0 10 7 23 15 30 8 7 22 35 30 62 8 28 20 57 26 65
13 15 74 158 89 206 5 15 17 42 28 60 11 18 23 45 27 62 3 16 12 36 18 43 11
14 43 87 79 182 11 30 27 62 35 72 8 9 21 37 28 62 8 26 21 56 30 68 8 13 15
28 15 36 0 7 11 37 24 65 13 29 40 93 62 142 21 50 46 102 55 116 10 14 21 38
24 55 7 28 13 44 68 164 14 30 30 71 36 91 6 20 23 51 36 69 14 19 25 43 25
55 0 11 7 31 16 44 16 22 33 63 78 186 12 33 32 78 43 100 48 93 83 171 83
186 0 9 6 22 14 28 8 7 18 27 22 44 6 31 34 94 95 222 16 33 29 67 29 76 0 9
6 22 14 28 8 7 18 27 21 44 4 18 34 87 66 153 32 67 59 130 59 141 0 11 9 31
19 45 11 13 23 38 27 54 4 16 15 45 25 64 29 58 56 122 79 183 12 31 28 63 35
71 7 7 19 33 25 57 7 24 18 51 25 59 7 8 16 26 19 40 16 57 87 218 102 231 7
6 16 25 19 42 4 18 16 46 26 63 10 17 19 38 19 45 0 8 9 31 20 52 11 20 20 42
20 49 0 6 20 52 44 102 67 137 86 181 86 195 0 7 8 30 18 50 32 62 60 124 86
186 13 33 31 74 40 90 9 17 16 36 16 43 0 7 9 30 20 50 35 68 80 168 80 182 0
7 13 38 28 69 51 99 81 165 88 191 3 14 12 32 19 40 7 8 17 33 21 55 4 22 15
50 25 63 10 13 27 47 38 77 11 29 29 68 40 86 12 19 21 42 21 52 0 11 9 30 20
44 11 14 20 35 20 46 0 20 6 34 82 197 58 123 74 159 93 212 9 26 21 50 26 53
5 4 9 15 9 27 0 11 9 37 19 57 11 20 40 83 66 141 26 58 56 123 66 146 11 22
19 46 19 53 0 7 8 30 18 50 51 101 83 170 96 209 9 23 22 53 31 65 8 12 15 28
15 36 0 8 12 39 27 70 14 31 29 71 33 89 l8 32 -2383 0 -2383 0 -6 -34z"/>
          <path d="M10397 13433 c-10 -32 -75 -187 -91 -218 -9 -16 -16 -37 -16 -46 0
-9 -9 -27 -20 -41 -18 -23 -26 -42 -66 -153 -8 -22 -21 -54 -29 -71 -8 -16
-27 -60 -44 -96 -16 -36 -34 -69 -40 -72 -6 -4 -11 -17 -11 -29 0 -12 -11 -43
-25 -69 -14 -26 -25 -55 -25 -65 0 -10 -15 -43 -33 -73 -19 -31 -37 -69 -41
-85 -4 -17 -13 -37 -21 -45 -7 -8 -16 -33 -20 -54 -3 -22 -15 -51 -26 -64 -10
-14 -19 -34 -19 -45 0 -11 -13 -41 -30 -67 -16 -26 -39 -71 -49 -101 -11 -30
-27 -73 -35 -95 -15 -42 -42 -100 -76 -164 -10 -20 -37 -83 -59 -140 -23 -57
-50 -118 -61 -135 -11 -18 -20 -41 -20 -52 0 -12 -7 -26 -15 -33 -8 -7 -20
-31 -26 -54 -6 -22 -22 -63 -34 -91 -13 -27 -39 -85 -59 -127 -20 -43 -36 -83
-36 -90 0 -7 -9 -26 -20 -43 -11 -16 -20 -36 -20 -43 0 -14 -33 -89 -86 -196
-19 -37 -34 -75 -34 -82 0 -8 -11 -36 -25 -62 -14 -26 -25 -53 -25 -61 0 -8
-6 -22 -14 -30 -14 -16 -35 -66 -108 -251 -23 -58 -49 -114 -59 -125 -9 -11
-20 -31 -24 -45 -6 -22 -48 -126 -108 -265 -11 -27 -26 -57 -33 -65 -7 -8 -17
-35 -24 -60 -7 -25 -18 -52 -25 -60 -7 -8 -17 -28 -21 -45 -7 -29 -60 -151
-93 -215 -10 -19 -22 -48 -25 -64 -4 -16 -16 -41 -27 -54 -10 -14 -19 -32 -19
-41 0 -9 -9 -35 -21 -56 -27 -53 -99 -227 -99 -241 0 -6 -9 -19 -19 -28 -11
-10 -23 -32 -27 -49 -3 -17 -17 -50 -30 -75 -13 -25 -24 -50 -24 -56 0 -18
-54 -145 -109 -255 -28 -57 -51 -111 -51 -120 0 -9 -6 -25 -14 -36 -7 -11 -17
-31 -21 -45 -7 -25 -58 -136 -101 -223 -13 -26 -24 -54 -24 -62 0 -8 -20 -57
-44 -107 -57 -120 -116 -260 -116 -277 0 -7 -9 -26 -20 -41 -11 -16 -27 -48
-35 -71 -9 -23 -19 -47 -23 -53 -5 -6 -19 -40 -31 -76 -13 -36 -32 -79 -42
-96 -11 -17 -19 -36 -19 -42 0 -6 -11 -28 -25 -48 -14 -20 -25 -44 -25 -53 0
-9 -9 -33 -20 -54 -11 -20 -20 -43 -20 -49 0 -21 -76 -185 -99 -214 -12 -15
-21 -36 -21 -48 0 -11 -12 -43 -25 -71 -14 -27 -25 -56 -25 -63 0 -7 -9 -30
-19 -50 -27 -51 -88 -192 -97 -222 -3 -14 -15 -36 -25 -49 -11 -13 -19 -31
-19 -40 0 -9 -9 -34 -21 -55 -11 -21 -41 -89 -66 -150 -25 -61 -51 -118 -59
-127 -8 -8 -14 -22 -14 -30 0 -7 -11 -36 -24 -64 -35 -74 -73 -166 -80 -196
-3 -14 -19 -45 -36 -70 -16 -24 -30 -51 -30 -59 0 -8 -11 -36 -25 -62 -14 -26
-25 -52 -25 -59 0 -6 -6 -25 -14 -42 -8 -18 -31 -70 -51 -117 -20 -47 -50
-109 -66 -137 -16 -29 -29 -61 -29 -70 0 -10 -11 -39 -25 -65 -14 -26 -25 -56
-25 -66 0 -11 -6 -25 -13 -31 -8 -6 -19 -30 -26 -53 -7 -24 -26 -64 -41 -90
-16 -25 -34 -64 -40 -85 -5 -21 -17 -47 -25 -57 -8 -11 -15 -27 -15 -35 0 -9
-12 -38 -25 -66 -14 -27 -25 -58 -25 -67 0 -9 -16 -43 -35 -75 -19 -32 -35
-65 -35 -73 0 -8 -12 -33 -25 -55 -14 -22 -25 -47 -25 -55 0 -8 -9 -33 -19
-55 -10 -22 -30 -67 -44 -100 -40 -96 -45 -107 -72 -155 -14 -25 -25 -54 -25
-66 0 -12 -9 -32 -19 -46 -11 -13 -22 -41 -26 -61 -4 -20 -16 -48 -26 -61 -11
-13 -19 -29 -19 -34 0 -6 -18 -48 -40 -93 -22 -46 -40 -91 -40 -100 0 -9 -6
-22 -13 -28 -8 -6 -19 -29 -26 -52 -7 -22 -21 -55 -32 -72 -10 -18 -19 -40
-19 -49 0 -10 -9 -32 -20 -50 -11 -18 -20 -39 -20 -46 0 -20 -37 -97 -54 -111
-8 -7 -18 -27 -22 -44 -3 -18 -14 -48 -24 -67 -10 -19 -23 -51 -30 -70 -36
-101 -48 -130 -76 -180 -17 -30 -35 -66 -39 -79 -4 -14 -16 -38 -26 -55 -11
-17 -19 -39 -19 -49 0 -9 -9 -34 -20 -54 -11 -21 -20 -44 -20 -51 0 -7 -15
-43 -34 -80 -56 -109 -86 -173 -86 -185 0 -7 -11 -34 -25 -62 -13 -27 -25 -57
-25 -66 0 -9 -7 -22 -15 -29 -8 -7 -20 -31 -26 -54 -6 -22 -24 -65 -40 -95
-16 -30 -29 -59 -29 -63 0 -9 -22 -59 -76 -173 -13 -28 -24 -56 -24 -62 0 -12
-50 -119 -95 -204 -14 -26 -25 -54 -25 -62 0 -9 -9 -31 -19 -49 -11 -18 -23
-50 -26 -70 -4 -21 -13 -42 -21 -49 -8 -6 -14 -17 -14 -23 0 -6 -19 -49 -41
-96 -64 -130 -78 -162 -85 -195 -7 -31 -14 -47 -76 -170 -20 -38 -39 -81 -43
-95 -7 -26 -45 -112 -71 -163 -8 -16 -14 -34 -14 -41 0 -7 -11 -34 -25 -60
-14 -26 -25 -52 -25 -59 0 -7 -13 -37 -30 -67 -16 -30 -29 -58 -30 -62 0 -5
1044 -8 2320 -8 2144 0 2320 1 2320 16 0 9 9 35 19 58 44 93 81 176 81 182 0
3 15 38 33 77 19 40 44 97 56 127 13 30 34 78 47 106 13 28 24 54 24 58 0 7
32 77 96 211 13 28 24 57 24 65 0 8 9 29 20 45 11 17 20 36 20 43 0 16 12 45
77 182 25 52 56 118 69 146 13 28 24 57 24 64 0 13 19 55 86 194 19 38 34 75
34 83 0 8 9 31 19 51 41 78 71 148 71 162 0 9 6 24 14 35 7 11 26 52 41 90 15
39 34 77 41 86 8 8 14 24 14 35 0 10 11 37 25 59 13 22 25 46 25 53 0 7 16 47
35 88 19 41 35 77 35 81 0 4 23 53 50 109 28 56 50 107 50 113 0 6 9 28 20 48
11 21 20 43 20 49 0 17 78 186 95 204 7 8 20 41 29 72 9 31 21 60 26 63 6 3
10 14 10 23 0 17 15 50 91 208 21 45 39 88 39 95 0 8 9 28 20 44 11 17 20 36
20 44 0 8 9 32 19 55 33 68 98 220 105 243 4 11 13 27 21 33 8 7 15 22 15 34
0 11 11 39 25 61 13 22 25 49 25 60 0 11 7 30 15 41 8 12 26 52 40 90 14 37
32 75 40 84 7 8 17 29 21 45 4 17 17 50 30 75 13 25 24 51 24 57 0 7 9 30 20
50 11 21 20 43 20 50 0 7 15 41 34 75 19 35 37 74 41 88 4 14 16 36 26 49 11
13 19 35 19 50 0 14 9 40 20 56 11 17 20 36 20 44 0 7 29 74 65 147 36 74 65
138 65 144 0 10 32 84 60 140 10 20 23 54 29 76 6 23 24 61 41 85 16 24 30 52
30 62 0 10 11 39 25 65 14 26 25 54 25 62 0 8 9 29 20 45 11 17 20 36 20 43 0
15 37 98 86 194 19 38 34 74 34 82 0 8 11 36 25 62 14 26 25 53 25 61 0 8 9
31 20 51 46 86 100 206 100 222 0 10 6 23 14 29 8 7 18 27 22 44 3 18 17 52
30 77 13 25 24 52 24 60 0 9 6 24 14 35 7 11 26 49 41 85 15 36 33 72 41 81 8
8 14 25 14 36 0 11 11 41 25 67 14 26 25 52 25 59 0 7 12 35 25 62 14 28 25
56 25 63 0 8 15 37 34 65 18 29 36 68 40 88 4 21 13 42 20 48 8 7 17 28 21 49
4 20 13 48 20 62 18 35 110 227 129 272 9 20 16 42 16 50 0 7 8 29 18 48 11
19 22 48 26 64 4 16 16 41 27 54 10 14 19 30 19 37 0 7 18 53 41 102 23 48 50
106 60 129 10 23 19 47 19 54 0 6 9 24 19 39 10 14 24 44 30 66 6 22 17 49 24
60 8 11 24 47 36 80 13 33 28 67 36 75 7 8 16 31 20 50 4 19 13 42 20 50 7 8
18 35 25 60 7 25 17 52 24 60 7 8 23 40 36 70 13 30 32 67 42 82 10 14 18 34
18 45 0 10 8 34 19 53 10 19 22 51 26 70 4 19 13 42 20 50 7 8 18 31 24 51 6
20 25 57 42 84 16 26 33 61 36 79 3 17 14 48 24 68 11 21 19 44 19 51 0 8 9
26 20 40 11 14 20 32 20 41 0 9 14 42 30 73 17 31 30 61 30 68 0 18 50 105 61
105 5 0 9 741 9 1855 l0 1855 -1524 0 c-1442 0 -1524 -1 -1529 -17z"/>
          <path d="M13409 7108 c-6 -24 -17 -54 -24 -68 -7 -14 -31 -65 -54 -115 -23
-49 -51 -106 -61 -126 -10 -19 -22 -53 -26 -75 -4 -21 -15 -49 -24 -61 -10
-13 -21 -38 -24 -57 -4 -19 -22 -58 -41 -87 -19 -28 -35 -57 -35 -64 0 -8 -9
-29 -19 -47 -10 -18 -22 -49 -25 -69 -4 -21 -13 -42 -20 -48 -8 -7 -17 -28
-21 -49 -4 -20 -15 -50 -25 -67 -9 -16 -23 -46 -29 -66 -6 -20 -23 -51 -36
-69 -14 -19 -28 -50 -31 -69 -4 -20 -13 -45 -21 -56 -7 -11 -18 -38 -24 -60
-6 -21 -19 -50 -30 -63 -10 -14 -19 -31 -19 -39 0 -8 -16 -44 -35 -80 -19 -36
-35 -70 -35 -76 0 -5 -9 -28 -19 -51 -51 -109 -69 -153 -75 -178 -3 -15 -22
-53 -41 -85 -19 -32 -35 -65 -35 -74 0 -9 -9 -28 -20 -42 -11 -14 -20 -36 -20
-49 0 -12 -9 -37 -19 -54 -10 -17 -22 -46 -26 -65 -4 -19 -15 -47 -25 -64 -9
-16 -21 -43 -24 -59 -4 -17 -20 -46 -35 -65 -16 -20 -32 -52 -36 -71 -4 -19
-16 -49 -26 -66 -10 -17 -19 -42 -19 -55 0 -13 -11 -42 -25 -64 -13 -22 -25
-47 -25 -56 0 -8 -11 -30 -25 -49 -13 -18 -34 -62 -45 -96 -12 -35 -26 -66
-31 -69 -5 -4 -9 -14 -9 -24 0 -10 -11 -38 -23 -62 -13 -24 -27 -57 -31 -74
-4 -16 -14 -37 -22 -46 -8 -9 -25 -45 -38 -80 -13 -35 -42 -103 -65 -150 -22
-47 -41 -93 -41 -100 0 -8 -6 -24 -14 -35 -8 -11 -19 -39 -26 -63 -6 -24 -22
-60 -34 -80 -34 -56 -86 -168 -86 -188 0 -10 -12 -40 -25 -68 -14 -27 -25 -60
-25 -71 0 -12 -7 -27 -16 -35 -18 -15 -84 -150 -108 -221 -39 -117 -51 -144
-68 -167 -10 -13 -18 -30 -18 -37 0 -7 -16 -42 -35 -77 -19 -35 -35 -71 -35
-80 0 -9 -11 -37 -25 -63 -14 -26 -25 -53 -25 -61 0 -8 -9 -26 -20 -40 -11
-14 -20 -35 -20 -47 0 -11 -7 -32 -15 -46 -27 -46 -99 -203 -110 -240 -4 -14
-14 -34 -21 -45 -8 -11 -14 -27 -14 -36 0 -8 -20 -56 -45 -105 -25 -49 -54
-113 -65 -142 -11 -29 -28 -70 -39 -90 -10 -20 -21 -52 -24 -70 -4 -19 -16
-45 -27 -59 -11 -14 -20 -33 -20 -42 0 -9 -14 -36 -30 -61 -16 -25 -30 -51
-30 -58 0 -18 -69 -193 -86 -216 -8 -11 -14 -28 -14 -37 0 -9 -9 -28 -20 -42
-12 -14 -29 -50 -39 -79 -25 -70 -79 -199 -90 -212 -5 -6 -12 -25 -15 -43 -4
-17 -14 -37 -22 -43 -7 -7 -19 -31 -25 -54 -6 -22 -35 -91 -65 -152 -30 -61
-54 -119 -54 -129 0 -10 -9 -32 -19 -49 -10 -17 -22 -47 -26 -66 -4 -19 -15
-44 -25 -55 -27 -30 -90 -182 -90 -215 l0 -30 1555 0 1555 0 0 3575 c0 3129
-2 3575 -15 3575 -8 0 -19 -17 -26 -42z"/>
        </g>
      </svg>
      {/*
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 512">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
          <path
            fill="url(#BG1)"
            d="M183.168 285.573l-2.918 5.298-2.973 5.363-2.846 5.095-2.274 4.043-2.186 3.857-2.506 4.383-1.6 2.774-2.294 3.939-1.099 1.869-1.416 2.388-1.025 1.713-1.317 2.18-.95 1.558-1.514 2.447-.866 1.38-.833 1.312-.802 1.246-.77 1.18-.739 1.111-.935 1.38-.664.956-.425.6-.41.572-.59.8-.376.497-.537.69-.171.214c-10.76 13.37-22.496 23.493-36.93 29.334-30.346 14.262-68.07 14.929-97.202-2.704l72.347-124.682 2.8-1.72c49.257-29.326 73.08 1.117 94.02 40.927z"
          />
          <path
            fill="url(#BG2)"
            d="M444.31 229.726c-46.27-80.956-94.1-157.228-149.043-45.344-7.516 14.384-12.995 42.337-25.267 42.337v-.142c-12.272 0-17.75-27.953-25.265-42.337C189.79 72.356 141.96 148.628 95.69 229.584c-3.483 6.106-6.828 11.932-9.69 16.996 106.038-67.127 97.11 135.667 184 137.278V384c86.891-1.611 77.962-204.405 184-137.28-2.86-5.062-6.206-10.888-9.69-16.994"
          />
          <path
            fill="url(#BG3)"
            d="M450 384c26.509 0 48-21.491 48-48s-21.491-48-48-48-48 21.491-48 48 21.491 48 48 48"
          />
        </g>
      </svg>
      */}
    </Box>
  );
}
