// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Label from '../../components/Label';
import SvgIconStyle from '../../components/SvgIconStyle';
// ----------------------------------------------------------------------
const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  car: getIcon('car-solid'),
  up: getIcon('upload'),
  down: getIcon('download'),
  cloudup: getIcon('cloud-upload'),
  clouddown: getIcon('cloud-download'),
  userSolid: getIcon('users-solid'),
  mec: getIcon('mec'),
  dwave: getIcon('money-bill'),
  dhand: getIcon('hand-usd'),
  clock: getIcon('clock-regular'),
  tool: getIcon('tools-solid')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'app',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard
      },
      { title: 'workshop', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
      { title: 'mechanics', path: PATH_DASHBOARD.general.mec, icon: ICONS.mec },
      {
        title: 'analytics',
        path: PATH_DASHBOARD.general.root,
        icon: ICONS.analytics,
        children: [
          { title: 'general', path: PATH_DASHBOARD.general.analytics },
          { title: 'user', path: PATH_DASHBOARD.general.useranalytics },
          { title: 'car', path: PATH_DASHBOARD.general.caranalytics }
        ]
      },
      { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
      { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
    ]
  },
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // MANAGEMENT : USER
      {
        title: 'customer',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.userSolid,
        children: [
          // { title: 'profile', path: PATH_DASHBOARD.user.profile },
          // { title: 'cards', path: PATH_DASHBOARD.user.cards },
          { title: 'car', path: PATH_DASHBOARD.user.car },
          { title: 'customer', path: PATH_DASHBOARD.user.list },
          // { title: 'edit', path: PATH_DASHBOARD.user.editById },
          // { title: 'account', path: PATH_DASHBOARD.user.account }
        ]
      },
      // Product
      {
        title: 'product',
        path: PATH_DASHBOARD.product.root,
        icon: ICONS.ecommerce,
        children: [
          { title: 'supplier', path: PATH_DASHBOARD.product.supp },
          { title: 'part', path: PATH_DASHBOARD.product.part },
          { title: 'paket', path: PATH_DASHBOARD.product.paket },
          { title: 'jasa', path: PATH_DASHBOARD.product.jasa },
        ]
      },
      // MANAGEMENT : E-COMMERCE
      {
        title: 'workshop',
        path: PATH_DASHBOARD.eCommerce.root,
        icon: ICONS.tool,
        children: [
          { title: 'scanner', path: PATH_DASHBOARD.eCommerce.scanner },
          { title: 'inspection', path: PATH_DASHBOARD.eCommerce.inspection },
          { title: 'general check', path: PATH_DASHBOARD.eCommerce.gc },
          { title: 'PR / Todo', path: PATH_DASHBOARD.eCommerce.pr },
          // { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
          // { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
          { title: 'estimation', path: PATH_DASHBOARD.eCommerce.wolist },
          { title: 'work order', path: PATH_DASHBOARD.eCommerce.wolist },
          { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoicelist },
          // { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
          // { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
          // { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
          // { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
          // { title: 'work order', path: PATH_DASHBOARD.eCommerce.workorder },
          // { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice }
        ]
      }
      /*
            // MANAGEMENT : BLOG
            {
              title: 'blog',
              path: PATH_DASHBOARD.blog.root,
              icon: ICONS.blog,
              children: [
                { title: 'posts', path: PATH_DASHBOARD.blog.posts },
                { title: 'post', path: PATH_DASHBOARD.blog.postById },
                { title: 'new post', path: PATH_DASHBOARD.blog.newPost }
              ]
            }
            */
    ]
  },
  {
    subheader: 'cashflow',
    items: [
      {
        title: 'cash flow',
        path: PATH_DASHBOARD.cashflow.root,
        icon: ICONS.dwave,
      },
      {
        title: 'hutang piutang',
        path: PATH_DASHBOARD.cashflow.hutangpiutang,
        icon: ICONS.dhand,
      }
    ]
  },
  // APP
  // ----------------------------------------------------------------------
  {
    subheader: 'app',
    items: [
      { title: 'time tracking', path: PATH_DASHBOARD.timetracking, icon: ICONS.clock },
      {
        title: 'mail',
        path: PATH_DASHBOARD.mail.root,
        icon: ICONS.mail,
        info: <Label color="error">2</Label>
      },
      { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
      { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
      {
        title: 'kanban',
        path: PATH_DASHBOARD.kanban,
        icon: ICONS.kanban
      }
    ]
  }
];

export default sidebarConfig;
